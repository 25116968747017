@import "../../assets/Fonts/Fonts.scss";


.deleteModalContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    min-width: 360px;

    .primaryText {
        margin: 0;
        font-weight: 700;
        font-size: 15px;
        font-family: $Epilogue;
    }

    .secondaryText {
        margin: 8px 0 0;
        font-weight: 400;
        font-size: 12px;
        font-family: $Epilogue;
    }

    .deleteModalButtons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
    }
}


.errorModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 24px;
    min-width: 360px;

    .errorText {
        margin: 0;
        font-weight: 600;
        font-size: 16px;
        font-family: $Epilogue;
        text-align: center;
    }

    .deleteModalButtons {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
    }
}