@import "../../assets//responsive.scss";

.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .not_found_img {
        height: 60%;
        width: auto;
        margin-bottom: 16px;
        @media #{$mq7b} {
            height: auto;
            width: 80%;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .page_title {
        margin: 0;
        margin-bottom: 16px;
        font-size: 30px;
        font-weight: 700;
    }

}