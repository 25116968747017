@import "../../assets/Fonts/Fonts.scss";
@import "../../assets//responsive.scss";

.loginContainer {
    height: 100vh;
    display: flex;

    flex-direction: column;
    justify-content: center;

    .imgWrapper {
        height: 80%;
        display: flex;
        // align-items: center;
        justify-content: flex-end;
        border-right: 1px solid rgba(153, 153, 153, 1);
        padding: 40px 30px 0 0;

        img {
            // width: 100%;
            max-height: 200px;
            max-width: 100%;
            object-fit: contain;

            @media #{$mq8b} {
                height: auto;
                width: 80%;
                max-width: 500px;
                padding: 40px 0 0 0;
            }
        }

        @media #{$mq8b} {
            justify-content: center;
            border: none;
        }
    }

    .formWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        h1,
        h4 {
            margin: 0;
            padding-bottom: 10px;
            font-family: $Epilogue;
            color: var(--primary-color);
        }

        h1 {
            font-weight: 600;
            font-size: 30px;
            padding-top: 0;
            width: 80%;
            line-height: 35px;

            @media #{$mq7b} {
                font-size: 20px;
            }
        }

        h4 {
            font-weight: 500;
            width: 80%;
            font-size: 15px;
            color: #171A1FFF;
            text-align: left;
            font-family: $Epilogue;
        }

        .customFormWrapper {
            width: 80%;
            display: flex;
            flex-direction: column;

            @media #{$mq7b} {
                width: 90%;
            }

            .inputWrapper {
                position: relative;

                input {
                    margin: 15px 0;
                    padding: 13px;
                    padding-left: 40px;
                    background-color: #f3f4f6ff;
                    border: 1px solid #f3f4f6ff;
                    border-radius: 5px;
                    width: 90%;

                    @media #{$mq7b} {
                        width: 85%;
                    }

                    &:focus {
                        outline: 0;
                        border: 1px solid var(--secondary-color) !important;
                    }

                    &::placeholder {
                        display: block;
                    }
                }
            }

            .inputFieldErrorMessage {
                margin: 0;
                font-family: $Epilogue;
                font-size: 12px;
                width: 80%;
                color: #f00;
                position: absolute;
                bottom: -8px;
            }
        }

        .forgotPasswordWrapper {
            width: 80%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 8px;

            p {
                display: flex;
                align-items: center;
                margin: 5px 0;
                font-family: $Epilogue;
                font-size: 12px;
                font-weight: 400;
                cursor: pointer;

                &:last-child {
                    text-decoration: underline;
                }
            }
        }


        .loginButtonWrapper {
            display: flex;
            width: 80%;
            justify-content: space-between;
            position: relative;

            button {
                width: 34%;
                font-size: 14px;
                margin-top: 10px;
                text-transform: capitalize;

                &:last-child {
                    display: flex;
                    justify-content: space-between;
                }
            }

            p {
                width: 30%;
                text-align: center;
                position: relative;

                &:after {
                    content: "";
                    width: 40%;
                    position: absolute;
                    top: 50%;
                    height: 1px;
                    background: #565E6DFF;
                    right: 0;
                }

                &:before {
                    content: "";
                    width: 40%;
                    position: absolute;
                    top: 50%;
                    height: 1px;
                    background: #565E6DFF;
                    left: 0;
                }
            }
        }

        .buttonWrapper {
            display: flex;
            width: 80%;
            justify-content: flex-end;
        }
    }
}


/* tooltip content styles */
.tooltipContent {
    font-family: $Epilogue;
    line-height: 18px;
    text-align: center;
}