@import "../../assets/Fonts/Fonts.scss";

.menuPopover {
    .MuiPopover-paper {
        border-radius: 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .menuContainer {
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    .menuItem {
        display: flex;
        align-items: center;
        padding: 0 20px;
        gap: 14px;
        min-width: 150px;
        height: 36px;
        border-left: 4px solid #FFFFFFFF;
        font-size: 12px;
        font-weight: 400;
        color: var(--primary-color);
        line-height: 20px;
        font-family: $Epilogue;

        &:hover {
            cursor: pointer;
            border-left: 4px solid var(--secondary-color);
            font-weight: 700;
        }
    }
}