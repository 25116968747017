@import "../../../assets/Fonts/Fonts.scss";
@import "../../../assets/variable.scss";
@import "../../../assets/responsive.scss";


.header {
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: space-between;

    .flex {
        padding: 14px 24px;
    }
}

.flex {
    display: flex;
    padding: 28px 24px;

    .headerText {
        font-size: $primaryHeading;
        font-weight: 700;
        line-height: 48px;
        font-family: $Epilogue;
        color: var(--primary-color);
        margin: 0;
    }

    .BackButton {
        color: #4a2714ff;
        margin-left: 20px;
        padding-top: 15px;
    }
}

.close {
    margin: 20px 30px 20px 20px;

}

.right {
    float: right;

    margin-right: 8% !important;
}

.loaderContainer {
    min-width: 900px;
}

.headerTypography {
    font-family: $Epilogue;
    font-size: $primaryHeading;
    font-weight: 700;
    padding-top: 15px;
    padding-left: 15px;
    color: var(--primary-color);

}

.mainContainer {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    padding-bottom: 30px;

    .addContactBtnWrapper {
        padding: 10px 30px 10px 30px;
    }


    .formHeader {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        font-family: $Epilogue;
        color: var(--primary-color);
        margin: 0;
        padding: 15px 0 15px 15px;
        text-indent: 12px;
    }

    .ViewCard {
        display: flex;
        flex-direction: column;
        grid-template-columns: repeat(3, minmax(100px, 1fr));
        margin: 0px 10px 20px;
        background: #fff !important;

    }

    .UserCard {
        display: flex;
        flex-direction: column;
        margin: 0px 24px 20px;
        grid-template-columns: repeat(3, minmax(100px, 1fr));
        background: #fff !important;
        border-radius: 5px;
        border-width: 1px;
        border-color: #f3f4f6ff;
        border-style: solid;
        box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.25);

        .typography {
            font-family: $Epilogue;
            font-size: 20px;
            padding-top: 15px;
            padding-left: 15px;
            color: var(--primary-color);
        }


    }

    .Form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        flex-grow: 1;
        padding: 10px 30px 10px 30px;

        .clientDetailsFormWrapper {
            border-bottom: 1px solid #CCC;
            padding-bottom: 30px;

            .dropdownLabel {
                margin: 0;
                font-size: 12px;
                color: #999;
                padding-bottom: 7px;
                font-family: $Epilogue;

                span {
                    font-style: italic;
                }
            }

            .groupBtnWrapper {
                display: flex;
                justify-content: flex-end;
                padding: 15px 0;

                button {

                    a {
                        display: flex;
                        color: #fff;
                        text-decoration: none;
                        font-family: $Epilogue;

                        img {
                            padding-right: 10px;
                        }
                    }
                }
            }
        }

        .Subtypography {
            color: var(--primary-color);
            font-family: $Epilogue;
            font-size: 20px;
            font-weight: 600;
        }

        .Buttonright {
            margin-top: 25px;
        }

        .contactListContainer {
            display: flex;
            flex-direction: column;
            padding-top: 20px;

            @media #{$mq9b} {
                padding-bottom: 24px;
                border-bottom: 1px solid grey;
            }

            .ActionButtons {
                margin-top: 26px;

                @media #{$mq8b} {
                    margin-top: -8px;
                }

            }
        }
    }

    .BottomButtons {
        margin-top: 15px;
        padding-right: 10px;
        padding-bottom: 10px;

        .BottomButton {
            margin-right: 10px;

        }

        .cancel {
            color: grey;
            border-width: 1px;
            border-color: grey;
        }

        .save {
            background-color: grey;
            color: white;
            border-width: 1px;
            border-color: rgb(110, 110, 110);

        }

    }
}