@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/variable.scss";
@import "../../assets/responsive.scss";

.formButtonWrapper {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

h2 {
  font-family: $Epilogue;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: var(--primary-color);
  margin-bottom: 20px;
}
.togglePasswordWrapper {
  width: 100% !important;
  margin-top: 20px;
}
.profileContainer{
  padding: 28px 40px;
  background-color: #ffffff;
  margin-top: 40px;
}
h4{
  font-weight: 600;
  font-size: 20px;
}
