@import "../../assets/Fonts/Fonts.scss";
@import "../../assets//responsive.scss";


.settingsContainer {
  min-height: 500px;
  padding: 0 30px 20px 30px;
  background-color: #fff;
  margin: 30px 0;

  .settingTitle {
    p {
      font-size: 24px;
      font-weight: 600;
      font-family: $Epilogue;
    }

  }
}

.logoInputContainer {
  display: flex;
  flex-direction: column;
}

.colorInputContainer {
  display: flex;
  flex-direction: column;
}

.commonLabel {
  font-size: 14px;
  margin-bottom: 5px;
  color: #999999;
}

.imgInputContainer {
  display: flex;
  border: solid gray 1px;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  padding-inline: 10px;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.uploadImg {
  margin-left: auto;
}

.fileInput {
  display: none;
}

.fileInputLabel {
  font-size: 12px;
}

.fileInfoLabel {
  // margin-left: 20px;
  font-size: 12px;
  color: #999999;
  padding-top: 10px;
}

.colorInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.colorValueHolder {
  height: 40px;
  width: 100%;
  border: solid gray 1px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-inline: 10px;
  color: gray;
  font-size: 13px;
  cursor: pointer;
}

.colorInput {
  margin-left: auto;
  outline: none;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.yourImage {
  height: 200px;
  width: 200px;
  border: solid 1px #999;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settingsButtonContainer {
  gap: 20px;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}

.settingsImgHolder {
  height: 180px;
  width: 180px;
  object-fit: contain;
}