@import "../../../../assets/Fonts/Fonts.scss";
@import "../../../../assets/variable.scss";
@import "../../../../assets/responsive.scss";

.headerCardContainer {
  display: flex;
  box-sizing: border-box;
  min-height: 100px;
  padding: 16px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px #cccccc40;

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    .contentText {
      display: flex;
      flex-direction: column;
      .value {
        font-family: $Epilogue;
        font-size: 26px;
        font-weight: 600;
        line-height: 42px;
        text-align: left;
        margin: 0;
      }
      .label {
        font-family: $Epilogue;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin: 0;
      }
    }
  }
}
