@import "../../assets/Fonts/Fonts.scss";
@import "../../assets//responsive.scss";

.organizationContainer {
    height: 100vh;
    display: flex;

    flex-direction: column;
    justify-content: center;

    .imgWrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0px 40px 0 0;
        border-right: 1px solid rgba(153, 153, 153, 1);

        img {
            // width: 100%;
            height: 200px;

            @media #{$mq8b} {
                height: auto;
                width: 80%;
                max-width: 500px;
            }
        }

        @media #{$mq8b} {
            justify-content: center;
            border: none;
            height: auto;
            padding: 40px 0 0 0;
        }
    }

    .formWrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;

        h1,
        h4 {
            margin: 0;
            padding-bottom: 10px;
            font-family: $Epilogue;
            color: var(--primary-color);
        }

        h1 {
            font-weight: 600;
            font-size: 30px;
            padding-top: 0;
            width: 80%;
            line-height: 35px;

            @media #{$mq7b} {
                font-size: 20px;
            }
        }

        h4 {
            font-weight: 400;
            // width: 80%;
            font-size: 15px;
            color: #171A1FFF;
            text-align: left;
            font-family: $Epilogue;
        }

        .customFormWrapper {
            width: 80%;
            display: flex;
            flex-direction: column;

            @media #{$mq7b} {
                width: 90%;
            }

            .inputWrapper {
                position: relative;
                display: flex;
                align-items: center;

                input {
                    margin: 15px 15px 15px 0;
                    padding: 18px;
                    background-color: #fff;
                    border: 1px solid #05aaff;
                    border-radius: 10px;
                    width: 70%;

                    @media #{$mq7b} {
                        width: 85%;
                    }

                    &:focus {
                        outline: 0;
                    }

                    &::placeholder {
                        display: block;
                    }
                }
            }

            .inputFieldErrorMessage {
                margin: 0;
                font-family: $Epilogue;
                font-size: 12px;
                width: 80%;
                color: #f00;
                position: absolute;
                bottom: -8px;
            }
        }

        .forgotPasswordWrapper {
            width: 30%;

            p {
                display: flex;
                align-items: center;
                margin: 5px 0 12px 0;
                font-family: $Epilogue;
                font-size: 12px;
                font-weight: 400;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}


/* tooltip content styles */
.tooltipContent {
    font-family: $Epilogue;
    line-height: 18px;
    text-align: center;
}