@import "../../../assets/Fonts/Fonts.scss";
@import "../../../assets//responsive.scss";

.viewTicketSectionWrapper {
  // background-color: #F8F9FAFF;
  margin: 0 !important;
  // height: calc(80vh - 59.18px);
  position: relative;
  // overflow: auto;

  .requestDetailsContainer {
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.25);
    border-radius: 5px;
    padding: 28px 40px;

    h5 {
      font-family: $Epilogue;
      font-weight: 600;
      font-size: 24px;
      margin: 0;
      padding: 0 0 20px 0;
    }

    .firstGridLayout {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      padding: 20px;
      gap: 20px;
      box-sizing: border-box;
      margin-inline: auto;

      @media #{$mq7b} {
        display: block;
      }

      .formWrapper {
        width: 30%;
        padding: 10px;
        position: relative;

        @media #{$mq7b} {
          width: 90%;
          margin: auto;
        }

        .formIcon {
          font-size: 18px;
          padding-right: 2px;
        }

        .userIcon {
          @extend .formIcon;
        }

        p {
          font-family: $Epilogue;
          font-weight: 500;
          margin: 8px 0;
          font-size: 14px;
          color: #999 !important;
        }

        input {
          width: 90%;
          padding: 10px;
          border-radius: 8px;
          border: 1px solid #999;
          background-color: #fff;
          font-size: 14px;
          text-indent: 20px;
          font-family: $Epilogue;
        }
      }
    }

    .secondGridLayout {
      @extend .firstGridLayout;
    }

    .thirdGridLayout {
      @extend .firstGridLayout;
    }
  }

  .componentWrapper {
    .customButtonStyles {
      width: 100%;
      padding: 25px;

      .rotateIcon {
        transform: rotate(0) !important;
        transition: 0.2s ease;
      }

      .reverseIcon {
        transform: rotate(180deg) !important;
        transition: 0.2s ease;
      }
    }

    h5 {
      font-family: $Epilogue;
      font-weight: 600;
      padding-top: 22px;
      color: var(--primary-color);
      margin-left: 10px;
      font-size: 24px;
    }

    .tableWrapper {
      background-color: #fff;
      box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.25);
      border-radius: 5px;
      padding: 30px 40px 50px 40px;
      position: relative;

      h4 {
        font-family: $Epilogue;
        width: 100%;
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 15px;
      }

      .tableListWrapper {
        overflow: auto;
        list-style-type: none;
        margin: 0;
        padding: 0 0 10px 0;

        li {
          font-family: $Epilogue;
          font-size: 12px;
          font-weight: 500;
          padding-bottom: 8px;
          text-align: left;
          cursor: pointer;

          &:last-child {
            padding-bottom: 0;
          }

          span {
            padding-right: 5px;
            color: #000;
          }
        }
      }

      .tableContent {
        font-family: $Epilogue;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 0;
      }

      span {
        font-family: $Epilogue;
        font-size: 12px;
        font-weight: 500;
        color: #999;
      }

      .reminderButtonWrapper {
        padding-bottom: 20px;
        position: absolute;
        right: 0;
        bottom: 0;
        background: #fff;
        width: 96%;
        z-index: 50;
      }
    }
  }

  .dataTableWrapper {
    position: relative;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.25);
    border-radius: 5px;

    .document_list_table {
      padding: 28px 40px;

      .document_list_header {
        display: flex;
        justify-content: space-between;
      }

      h5 {
        font-size: 24px;
        margin: 0;
        font-weight: 600;
        padding-bottom: 15px;
      }

      p {
        font-family: $Epilogue;
        text-align: left;
        display: flex;
        align-items: center;
        padding: 5px;
        font-size: 12px;
        font-weight: 500;

        img {
          padding-right: 10px;
        }
      }

      .documentType {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .documentTypeIcon {
          width: 20px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          margin: 0;
          padding: 0;
        }
      }

      .requestAnswer {
        display: flex;
        word-break: break-all;
        padding: 8px;
        font-family: $Epilogue;
        font-size: 11px;
        font-weight: 400;
        line-height: 16.5px;
        border: 1px solid #cccccc;
        border-radius: 2px;
        max-height: 60px;
        margin: 10px 8px 10px 0px;
        overflow: auto;
      }

      .fileInputWrapper {
        list-style-type: none;
        padding-left: 0;
        overflow: auto;
        padding-right: 8px;

        li {
          font-family: $Epilogue;
          padding: 5px;
          margin-bottom: 10px;
          border: 1px solid #dedede;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #000 !important;
        }

        .viewMoreData {
          border-radius: 5px;
          cursor: pointer;
          background-color: #565e6dff;
          color: #fff;

          p {
            background-color: transparent;
            margin: 0;
            padding: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}

// custom tooltip styles.
.tooltipWrapper {
  padding: 8px;

  h6 {
    font-family: $Epilogue;
    margin: 0;
    font-size: 10px;
  }

  p {
    margin-bottom: 0%;
    font-family: $Epilogue;
    line-height: 20px;
    text-transform: capitalize;
  }
}

/* modal styles */
.customModalWrapper {
  padding: 20px;
  min-width: 600px;
  width: 100%;
  box-sizing: border-box;

  h6 {
    margin: 0;
    font-family: $Epilogue;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    padding-bottom: 20px;
  }

  .customModalContent {
    // background-color: #F3F4F6FF;
    // padding: 25px;

    h6 {
      margin: 0;
      font-family: $Epilogue;
      font-size: 15px;
    }

    .customModalTable {
      height: 300px;
      overflow: auto;
    }

    .documentNameWrapper {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      max-width: 200px;
      .iconWrapper {
        width: 20px;
      }
      p {
        margin: 0;
      }
    }

    .textAreaStyles {
      width: 300px;
      height: auto;
      min-height: 50px;
      padding-left: 10px;
      padding-top: 10px;
      border: 1px solid #ccc;
      border-radius: 10px;
      font-size: 12px;
      resize: none;
      margin-top: 20px;

      &::placeholder {
        padding-top: 5px;
        font-size: 12px;
      }
    }
  }

  .customModalButtonWrapper {
    display: flex;
    gap: 10px;
    padding: 10px 0 20px 0;
    justify-content: space-between;

    .inputCheckWrapper {
      display: flex;
      align-items: center;
      padding-left: 10px;

      p {
        font-family: $Epilogue;
        margin: 0;
        padding-left: 10px;
        font-weight: 600;
        font-size: 13px;
      }
    }

    .formButtonWrapper {
      display: flex;
    }
  }

  .autoReminderComponentWrapper {
    background-color: rgba(29, 29, 29, 0.02);
    padding: 20px;

    label {
      span {
        &:last-child {
          font-family: $Epilogue;
          color: #333;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    p {
      margin: 0;
      font-family: $Epilogue;
      font-size: 12px;
      padding-bottom: 10px;
      color: #333;
      font-weight: 500;
    }
  }

  .formBtn {
    display: flex;
    padding: 20px 0;
    justify-content: flex-end;
  }
}

.modalContent {
  width: 1000px;
  padding: 40px;
  overflow-x: hidden;
  position: relative;

  .maskBackground {
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #d1d1d1;
    top: 52px;
    right: 52px;
    z-index: 20;
  }

  svg {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
  }

  iframe {
    height: 100%;
    width: 100% !important;
  }
}

// .ndfHFb-c4YZDc-i5oIFb.ndfHFb-c4YZDc-e1YmVc .ndfHFb-c4YZDc-Wrql6b {
//     display: none;
// }
