.modalContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 40px 0 0;
    overflow-x: hidden;
    position: relative;
    box-sizing: border-box;

    .maskBackground {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #d1d1d1;
        top: 52px;
        right: 52px;
        z-index: 20;
    }

    svg {
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 30px;
        cursor: pointer;
    }

    iframe {
        height: 100%;
        width: 100% !important;
    }

    img{
        max-width: 90vw;
        max-height: calc(100vh - 100px);
    }
}