.renderFieldContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    // margin-left: 10px;
    margin-bottom: 10px;
  
}
.labelHolder{
    font-size: 14px;
    color: #999999;
    font-weight: 500;
}
.renderFieldHolder{
    box-sizing: border-box;
    width: 100%;
    min-height: 40px;
    border: solid #999999 1px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    padding-inline: 10px;
    font-size: 14px;
    font-weight: 400;

}