.toggleButtonContainer {
    display: flex;
    gap: 8px;

    .viewToggleButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 34px;
        gap: 0px;
        border-radius: 5px;
        cursor: pointer;
    }
}