@import "../../../assets/Fonts/Fonts.scss";
@import "../../../assets/variable.scss";

.ticketManagementDashboard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    font-family: $Epilogue !important;
    // color: var(--secondary-color);

    .ticketLabel {
        height: 30px;
        width: fit-content;
        padding: 0 12px;
        border-radius: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        margin-inline: auto;
    }

    .filterContainer {
        display: flex;
        gap: 15px;

        .filterFields {
            width: 180px;
        }
    }

    .viewSwitchButtonContainer {
        display: flex;
        justify-content: flex-end;
    }

    /* kanban view component styles */
    .kanbanComponentWrapper {
        width: 100%;
        // height: calc(100vh - 308px);
        overflow: auto;
        padding-bottom: 12px;

        .kanbanContainer {
            display: flex;
            gap: 22px;
        }

        .kanbanGrid {
            .gridContentWrapper {
                width: 333px;
                position: relative;
                height: 100%;
                display: flex;
                flex-direction: column;
                background-color: #fff;
                // border: 1px solid #dedede;
                border-top: none;

                .ticketStatus {
                    position: sticky;
                    top: 0;
                    z-index: 99;
                    background-color: #fff;
                    padding: 18px;
                    color: var(--primary-color);

                    .kanbanHeader {
                        font-family: $Epilogue;
                        margin: 0;
                        display: flex;
                        justify-content: space-between;
                        font-size: 18px;
                        position: relative;
                        border-bottom: 1px solid #ccc;
                        padding: 0 0 15px 0;

                        .kanbanHeaderLeft {
                            display: flex;
                            gap: 8px;
                        }

                        .statusName {
                            //   font-size: 24px;
                            font-weight: 600;
                            line-height: 36px;
                            margin: 0;
                        }

                        .itemLength {
                            border: 1px solid #000;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }

            .cardContentContainer {
                min-height: calc(100vh - 362px);
                box-sizing: border-box;
            }

            .cardContent {
                display: flex;
                flex-direction: column;
                width: 290px;
                gap: 10px;
                margin: auto;
                margin-bottom: 15px;
                padding: 4px 10px;
                background-color: #009FE31A;
                box-sizing: border-box;
                border-radius: 5px;
                cursor: pointer;

                p {
                    text-align: center;
                    font-family: $Epilogue;
                }

                .cardFirstData {
                    display: flex;
                    justify-content: space-between;
                }

                .ticketLabel {
                    height: 30px;
                    padding: 0 12px;
                    border-radius: 22px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0;
                    margin-top: 8px;
                }

                .cardRestData {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    padding-left: 10px;
                    padding-bottom: 16px;
                }

                .requestedClient {
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 28.5px;
                    text-align: left;
                    margin: 0;
                }

                .RequestItem {
                    display: flex;
                    gap: 4px;
                    align-items: center;

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        text-align: left;
                        margin: 0;
                    }
                }


                &:last-child {
                    margin-bottom: 30px;
                }
            }
        }
    }
}