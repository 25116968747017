:root {
    --primary-color: #000000;
    --secondary-color: #05aaff;
    --secondary-color-rgb: 5, 170, 255;
    --heading-font: 32px;
    --subheading-font: 20px;
    --body-text: 16px;

    @media screen and (max-width: 672px) {
        --heading-font: 26px;
        --subheading-font: 16px;
        --body-text: 12px;
    }

}