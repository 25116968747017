@import "../../assets/responsive.scss";
@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/variable.scss";

.user_management_dashboard {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 0px 22px;
  // background-color: #ffffff;
  height: 100%;

  .bulkUploadBtnWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
}

.Section_container {
  display: flex;
  gap: 10px;

  @media #{$mq8b} {
    width: 100%;
  }

  .Section_dropdown_container {
    width: 260px;

    @media #{$mq8b} {
      width: 100%;
    }
  }
}

/* bulk upload modal styles */
.bulkUploadModalContainer {
  padding: 20px;
  // width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-family: $Epilogue;
    font-size: 18px;
    text-align: center;
    font-weight: 600;

    span {
      color: #f00;
    }
  }
}

.modalBtnWrapper {
  display: flex;
  padding-bottom: 20px;
  justify-content: center;

  button {
    margin: 0 10px 0 10px;
  }
}