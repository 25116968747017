@import "../../assets/Fonts/Fonts.scss";
@import "../../assets//responsive.scss";

/* custom header styles */

.sideBarComponentWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100px;
    background-color: #FFFFFF;
    z-index: 50;
    padding-top: 24px;
    border-bottom: 1px solid #dedede;
    display: flex;
    justify-content: center;
    box-sizing: border-box;

    .logoWrapper {
        display: flex;
        width: 65px;
        align-items: flex-start;
        justify-content: center;

        img {
            width: 65px;
            height: auto;
        }

        p {
            display: none;
            margin: 0;
            font-family: $Epilogue;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
        }
    }

    @media #{$mq7b} {
        height: 60px;
        width: 100%;
        padding: 10px;
        justify-content: flex-start;

        .logoWrapper {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;

            img {
                width: auto;
                height: 40px;
            }

            p {
                display: flex;
            }
        }
    }
}

.documentRequestBody {
    background-color: #F5F5F5;
    height: 100vh;
    padding: 28px 50px 28px 150px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;

    .headerComponent {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
            font-family: $Epilogue;
            font-size: 32px;
            font-weight: 600;
            line-height: 50px;
            text-align: left;
            margin: 0;
        }
    }

    @media #{$mq7b} {
        padding: 80px 20px 20px;

        .headerComponent {
            display: none;
        }
    }
}

.documentRequestedWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #FFFFFF;
    padding: 40px;
    .documentRequestHeader {
        display: flex;
        justify-content: space-between;
        width: 70%;

        @media screen and (max-width: 1100px) {
            width: 100%;
        }
    }

    p {
        font-family: $Epilogue;
        color: var(--primary-color);
        font-size: 24px;
        font-weight: 600;
        line-height: 39px;
        margin: 0;
    }

    .document_list_table {
        width: 70%;
        overflow: auto;

        @media screen and (max-width: 1100px) {
            width: 100%;
        }

        .documentType {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: flex-start;
        }

        .documentTypeIcon {
            width: 20px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            margin: 0;
            padding: 0;
        }

        .fileInputWrapper {
            list-style-type: none;
            padding-left: 0;
            height: 170px;
            overflow: auto;

            li {
                font-family: $Epilogue;
                padding: 5px;
                margin-bottom: 10px;
                border: 1px solid #dedede;
                font-size: 13px;
                display: flex;
                align-items: center;
                position: relative;

            }

            .viewMoreData {
                border-radius: 5px;
                cursor: pointer;
                background-color: #565E6DFF;
                color: #fff;

                p {
                    background-color: transparent;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }

    .reminderButtonWrapper {
        display: flex;
        justify-content: flex-end;
        width: 70.5%;

        @media screen and (max-width: 1100px) {
            width: 100%;
        }
    }

    @media #{$mq7b} {
        padding: 20px;
        p{
            font-size: 14px;
            line-height: 20px;
        }
        .document_list_table{
            p{
                font-size: 12px;
            }
        }
    }
}

.documentUpload {
    padding: 18px 0px;

    .textArea {
        width: 220px;
        padding-bottom: 10px;
    }
}

.modalContainer {
    font-family: $Epilogue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    min-width: 500px;
    height: 250px;
    gap: 50px;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .modalTitle {
        font-size: 20px;
        font-weight: 700;
    }

    .modalButton {
        display: flex;
        justify-content: center;
    }
}