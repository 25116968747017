@import "../../assets/Fonts/Fonts.scss";
@import "../../assets//responsive.scss";

.componentWrapper {
    position: relative;

    ul {
        position: absolute;
        right: 20px;
        padding: 18px;
        width: 17%;
        border-radius: 10px;
        box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);

        li {
            font-family: $Epilogue;
            font-size: 11px;
            padding-bottom: 10px;
            font-weight: 500;
            line-height: 18px;

            &:last-child {
                padding-bottom: 0;
            }

        }
    }
}

.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .imgWrapper {
        height: 90%;
        display: flex;
        // align-items: center;
        justify-content: flex-end;
        border-right: 1px solid rgba(153, 153, 153, 1);
        padding: 40px 50px 0 0;

        img {
            // width: 100%;
            max-height: 200px;
            max-width: 100%;
            object-fit: contain;

            @media #{$mq8b} {
                height: auto;
                width: 80%;
                max-width: 500px;
            }
        }

        @media #{$mq8b} {
            justify-content: center;
            border: none;
            height: auto;
            padding: 40px 0 0 0;
        }
    }

    .formWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        h1,
        h4 {
            margin: 0;
            padding-bottom: 10px;
            font-family: $Epilogue;
            color: var(--primary-color);
        }

        h1 {
            font-weight: 600;
            font-size: $primaryHeading;
            padding-top: 0;
            width: 80%;
        }

        h4 {
            font-weight: 500;
            width: 80%;
            text-align: left;
            font-size: $subHeading;
        }

        .customFormWrapper {
            padding-top: 20px;
            width: 80%;
            display: flex;
            flex-direction: column;

            .passwordWrapper {
                position: relative;

                .label {
                    font-family: $Epilogue;
                    font-size: 12px;
                    padding-bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 26%;

                    @media #{$mq7b} {
                        width: 100%;
                    }
                }

                .label2 {
                    @extend .label;
                    padding-top: 15px;
                    width: 30%;

                    @media #{$mq7b} {
                        width: 100%;
                    }
                }

            }

            .pwdValidationSection {
                display: flex;
                border: 1px solid #dedede;
                width: 94%;
                border-radius: 8px;

                @media #{$mq7b} {
                    width: 97%;
                }

                .pwdValidationSection1 {
                    padding-left: 5px;

                    p {
                        font-size: 11px;
                        font-family: $Epilogue;
                        margin-bottom: 0;
                        line-height: 15px;
                    }


                }

                .pwdValidationSection2 {
                    padding-left: 10px;
                    padding-bottom: 0.5rem;

                    p {
                        font-family: $Epilogue;
                        font-size: 12px;
                        margin-bottom: 0;
                        line-height: 15px;
                    }
                }
            }

            input {
                margin: 10px 0;
                padding: 13px;
                background-color: #F3F4F6FF;
                border: 0;
                border-radius: 5px;
                width: 90%;

                &:focus {
                    outline: 0;
                    border: 1px solid var(--secondary-color);
                }

                &::placeholder {
                    display: block;

                }
            }

            .inputFieldErrorMessage {
                margin: 0;
                font-family: $Epilogue;
                font-size: 12px;
                width: 80%;
                color: #f00;
            }

        }
    }
}

/* modal styles */

.customModalContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    h6 {
        font-family: $Epilogue;
        font-size: 20px;
        margin: 0;
        font-weight: 600;
    }

    p {
        font-family: $Epilogue;
        text-align: center;
        line-height: 23px;
    }
}