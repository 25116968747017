@import "../../assets/Fonts/Fonts.scss";
@import "../../assets//variable.scss";
@import "../../assets/responsive.scss";

.sidenavWrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    padding-top: 59px;

    .sideBarCompact {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 60px;
        background-color: #fff;
        color: #fff;
        transition:
            width 0.5s ease,
            left 0.5s ease;
        height: calc(100vh - 60px);
        border-right: 1px solid #dedede;
    }

    .sideBarExtended {
        @extend .sideBarCompact;
        width: 200px;
    }

    .compactList {
        list-style-type: none;
        padding-left: 0;
        margin: 0;

        li {
            color: #000;
            display: flex;
            // justify-content: center;
            padding-bottom: 17px;
            cursor: pointer;
            width: 60px;

            span {
                width: 25px;
                height: 25px;

                img {
                    width: 20px;
                }
            }

            &:first-child {
                padding-top: 20px;
            }

            .activeLink {
                padding: 10px;
                color: var(--primary-color);

                &:first-child {
                    border-left: 4px solid var(--secondary-color);
                }
            }

            .inactiveLink {
                background-color: transparent;
                padding: 10px;
                color: #cccccc;

                &:first-child {
                    border-left: 4px solid transparent;
                }
            }
        }
    }

    .extendedList {
        @extend .compactList;
        overflow: hidden;

        li {
            width: 200px;

            span {
                // background-color: #f3f4f6ff;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 12px;
                font-family: $Epilogue;
                font-weight: 600;

                &:first-child {
                    width: 25px;
                    height: 25px;
                    // margin-left: 10px;
                }

                &:last-child {
                    width: 85%;
                    margin-right: 5px;
                    padding-left: 0;
                }
            }
        }
    }

    .sidebarBelowcompactList {
        @extend .compactList;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 40px;

        li {
            &:first-child {
                border-top: 1px solid #ccc;
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            span {
                background-color: transparent;
                padding: 10px;
                color: var(--primary-color);
                border-left: 4px solid transparent;
            }
        }
    }

    .sidebarBelowextendedList {
        @extend .extendedList;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 40px;

        li {
            &:first-child {
                border-top: 1px solid #ccc;
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            span {
                background-color: transparent;
                padding: 10px;
                color: var(--primary-color);
                border-left: 4px solid transparent;
            }
        }
    }
}

.logoutModalContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    min-width: 360px;

    p {
        margin: 0;
        font-weight: 700;
        font-size: $subHeading;
        font-family: $Epilogue;
    }

    .logoutModalButtons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
    }
}