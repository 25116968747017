@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/variable.scss";
@import "../../assets/responsive.scss";

.cliet_management_dashboard {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 20px 22px;
    // background-color: #f8f9faff;
    height: 100%;

    .actionItemWrapper {
        display: flex;
        align-items: center;

        ul {
            list-style-type: none;
            display: flex;
            justify-content: center;
            margin: 0;
            padding-left: 0;

            li {
                &:nth-of-type(1) {
                    padding-right: 10px;
                }

                &:nth-of-type(2) {
                    padding-right: 5px;
                }



                img {
                    width: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}

.Section_container {
    display: flex;
    gap: 10px;

    @media #{$mq8b} {
        width: 100%;
    }

    .Section_dropdown_container {
        width: 450px;
        display: flex;
        gap: 10px;

        @media #{$mq8b} {
            width: 100%;
        }

        // background-color: white;
    }
}

.bulkUploadBtnContainer {
    display: flex;
    justify-content: flex-end;
    padding: 12px 0;
    gap: 15px;
}

/* bulk upload modal styles */
.bulkUploadModalContainer {
    padding: 20px;
    // width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-family: $Epilogue;
        font-size: 18px;
        text-align: center;
        font-weight: 600;

        span {
            color: #f00;
        }
    }
}

.modalBtnWrapper {
    display: flex;
    padding-bottom: 20px;
    justify-content: center;

    button {
        margin: 0 10px 0 10px;
    }
}