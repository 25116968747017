@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/variable.scss";
@import "../../assets/responsive.scss";

.headerComponentWrapper {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 50;
    padding: 10px;
    border-bottom: 1px solid #dedede;
    display: flex;
    height: 60px;
    box-sizing: border-box;

    .sidebar_menu_icon_wrapper {
        width: 50px;
        background-color: #fff;
        display: flex;
        align-items: center;
        padding-left: 8px;
        color: var(--primary-color);
    }

    .logoWrapper {
        display: flex;
        gap: 10px;
        align-items: center;

        img {
            max-height: 50px;
            max-width: 80px;
        }

        h6 {
            margin: 0;
            font-family: $Epilogue;
            font-size: 15px;
            padding-left: 5px;
            color: var(--primary-color);
        }
    }
}