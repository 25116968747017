@import "../../assets/Fonts/Fonts.scss";
@import "../../assets//responsive.scss";

.close {
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
}

.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .imgWrapper {
        display: flex;
        justify-content: flex-end;
        border-right: 1px solid rgba(153, 153, 153, 1);
        padding: 30px 50px 0 0;
        height: 400px;
        // align-items: center;

        img {
            // width: 100%;
            max-height: 200px;
            max-width: 100%;
            object-fit: contain;

            @media #{$mq8b} {
                height: auto;
                width: 80%;
                max-width: 500px;
            }
        }

        @media #{$mq8b} {
            justify-content: center;
            border: none;
            height: auto;
            padding: 40px 0 0 0;
        }
    }

    .formWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        h1,
        h4 {
            margin: 0;
            padding-bottom: 10px;
            font-family: $Epilogue;
            color: var(--primary-color);
        }

        h1 {
            padding-top: 0;
            width: 80%;
            font-size: $primaryHeading;
            font-weight: 700;
            line-height: 48px;
        }

        h4 {
            width: 80%;
            text-align: left;
            font-weight: 400;
            line-height: 22px;
            color: #171a1fff;
            font-size: $subHeading;
            font-family: $Epilogue;
        }

        .customFormWrapper {
            padding-top: 20px;
            width: 80%;
            display: flex;
            flex-direction: column;

            @media #{$mq7b} {
                width: 90%;
            }

            .emailWrapper {
                position: relative;
            }

            .passwordWrapper {
                @extend .emailWrapper;
            }

            .inputFieldErrorMessage {
                margin: 0;
                font-family: $Epilogue;
                font-size: 12px;
                width: 80%;
                color: #f00;
                position: absolute;
            }

            input {
                margin: 10px 0;
                padding: 13px;
                padding-left: 40px;
                background-color: #f3f4f6ff;
                border: 0;
                border-radius: 5px;
                width: 90%;

                &:focus {
                    outline: 0;
                    border: 1px solid var(--secondary-color);
                }

                @media #{$mq7b} {
                    width: 85%;
                }

                &::placeholder {
                    display: block;
                }
            }
        }

        .forgotPasswordWrapper {
            width: 80%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                margin: 5px 0;
                font-family: $Epilogue;
                font-size: 12px;
                font-weight: 400;

                &:last-child {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .triggerEmailButtonWrapper {
            width: 80%;
            display: flex;
            justify-content: space-between;

            .backToLoginText {
                width: 80%;
                display: flex;
                font-family: $Epilogue;
                margin-top: 30px;
                align-items: center;
                font-size: $subHeading;

                span {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

/* modal styles */

.customModalContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    gap: 10px;

    .LoaderModal {
        min-width: 500px;
        min-height: 150px;
    }

    .timerClass {
        margin-left: 50%;
        display: flex;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #171a1fff;

        span {
            margin-top: 2px;
            font-family: $Epilogue;
        }
    }

    position: relative;

    h6 {
        font-family: $Epilogue;
        font-size: 30px;
        font-weight: 600;
        margin: 0;
    }

    p {
        font-family: $Epilogue;
        text-align: center;
        line-height: 23px;
        font-size: 15px;
    }
}