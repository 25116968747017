@import '../../assets/variable.scss';

.main {
    display: flex;

    .textlabel {
        font-weight: 400;
        font-size: 14px;
        margin-top: 10px;
        color: #999999;
    }
}