@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/variable.scss";
@import "../../assets/responsive.scss";

.dashboardContainer {
  padding: 20px;

  .dashboardHeader {
    display: flex;
    justify-content: space-between;

    .toggleSwitch {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .toggleText {
      font: {
        family: $Epilogue;
        size: 14px;
        weight: 600;
        line-height: 20px;
      }
      margin: 0;
    }
  }

  .headerText {
    font: {
      family: $Epilogue;
      size: 36px;
      weight: 600;
      line-height: 48px;
    }
    padding-bottom: 20px;
    margin: 0;
  }

  .dashboard {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
