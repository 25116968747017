@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/variable.scss";
@import "../../assets/responsive.scss";


.moduleContainer {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 20px 36px;
    box-sizing: border-box;

    .moduleHeader {
        font-family: $Epilogue;
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        color: var(--primary-color);
        margin: 0;
        padding: 0;
        display: flex;

        span {
            img {
                width: 35px;
                vertical-align: middle;
                margin-left: 10px;
            }
        }

        span {
            img {
                width: 35px;
                vertical-align: middle;
                margin-left: 10px;
            }
        }

    }
}

// only for the dashboard to hide the scrollbar.
.noScrollBar {
    @extend .moduleContainer;
    height: calc(100vh - 80px);
    padding: 20px 36px;
}