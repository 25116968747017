@import "../../../assets/Fonts/Fonts.scss";
@import "../../../assets/variable.scss";
@import "../../../assets/responsive.scss";

.groupContainerWrapper {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(204, 204, 204, 0.25);
    padding: 15px;
    margin: 30px 0;

    h6 {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        font-family: $Epilogue;
        color: var(--primary-color);
        margin: 0;
        text-indent: 12px;

    }

    .inputWrapper {
        padding: 30px 10px 30px 10px;
        border-bottom: 1px solid #CCCCCC;

        label {
            display: block;
            color: #999;
            font-size: 14px;
            font-family: $Epilogue;
            padding-bottom: 5px;
        }

        input {
            padding: 10px;
            width: 26%;
            border-radius: 8px;
            border: 1px solid #999;

            &::placeholder {
                font-family: $Epilogue;
                font-size: 12px;
            }
        }
    }

    .groupClientsWrapper {
        padding: 30px 10px 0px 10px;

        label {
            display: block;
            color: #999;
            font-size: 14px;
            font-family: $Epilogue;
            padding: 30px 0 0px 0;
        }

        .defaultSelectBoxWrapper {
            width: 40%;
            display: flex;
            // justify-content: space-between;
            padding: 10px 0;

            select {
                padding: 7px;
                width: 70%;
                margin-right: 10px;
                border: 1px solid #999;
                border-radius: 8px;
                font-family: $Epilogue;
            }

            button {
                width: 25%;
                background-color: var(--secondary-color);
                border: 0;
                border-radius: 10px;
                color: #fff;
                font-family: $Epilogue;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                img {
                    padding-right: 10px;
                }
            }
        }

        .dynamicSelectBoxWrapper {
            @extend .defaultSelectBoxWrapper;

            .btnActive {
                opacity: 1;
            }

            .btnInactive {
                opacity: 0.5;
            }
        }

        .formBtnWrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 20px;

            p {
                padding-right: 15px;
                font-family: $Epilogue;
                color: #f00;
                cursor: pointer;
            }

            input {
                width: 10%;
                background-color: var(--secondary-color);
                border: 0;
                border-radius: 8px;
                color: #FFF;
                cursor: pointer;
                height: 40px;
            }

            .activeInput {
                opacity: 1;
            }

            .inactiveInput {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

    }
}