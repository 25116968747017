.inputLabelComponent {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}

.fileUploadContainer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 12px;

    .uploadedFileList {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-height: 168px;
        padding-right: 10px;
        overflow-y: auto;
        overflow-x: hidden;

        .fileNameContainer {
            height: 30px;
            width: 220px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 10px;
            border-radius: 2px;
            border: 1px solid #CCCCCC;
            box-sizing: border-box;

            p {
                margin: 0;
                font-size: 11px !important;
                font-weight: 400 !important;
                background-color: transparent !important;
                line-height: 16.5px;
                color: #000000;
            }

            .postContent {
                display: flex;
                gap: 5px;
            }
        }
    }

    .fileUploadField {
        height: 30px;
        width: 220px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px 0px 15px;
        border-radius: 2px;
        border: 1px solid #CCCCCC;
        box-sizing: border-box;
        cursor: pointer;

        .placeholder {
            font-size: 11px;
            font-weight: 400;
            line-height: 16.5px;
            text-align: left;
            color: #999999;
        }
    }

    .innerIconWrap {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}