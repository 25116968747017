@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/responsive.scss";

.customButtonContainer {
    .MuiButton-root {
        height: 36px;
        width: max-content;
        padding: 0 12px;
        font-size: 12px;
        font-weight: 500;
        font-family: $Epilogue;
        text-transform: none;
        color: FFFFFFFF;
        background: var(--secondary-color);
        border-radius: 10px;
        gap: 6px;

        &:hover {
            color: #ffffffff;
            background: var(--secondary-color);
            opacity: 0.9;
        }

        &:active {
            color: #ffffffff;
            background: var(--secondary-color);
            opacity: 0.9;
        }
    }

    .MuiButton-outlined {
        color: var(--secondary-color);
        background: transparent;
        border: 1px solid var(--secondary-color);

        &:hover {
            color: var(--secondary-color);
            background: #dee1e6ff;
            border: 1px solid var(--secondary-color);
        }

        &:active {
            color: var(--secondary-color);
            background: #dee1e6ff;
            border: 1px solid var(--secondary-color);
        }
    }

    .MuiButton-text {
        color: var(--secondary-color);
        background: transparent;
        border: none;

        &:hover {
            color: var(--secondary-color);
            background: #dee1e6ff;
        }

        &:active {
            color: var(--secondary-color);
            background: #dee1e6ff;
        }
    }
}