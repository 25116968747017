@import "../../assets/Fonts/Fonts.scss";
@import "../../assets//responsive.scss";

.mainContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    margin: auto;

    .logoWrapper {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0px 30px 0 0;
        border-right: 1px solid rgba(153, 153, 153, 1);

        img {
            max-height: 200px;
            max-width: 100%;
            object-fit: contain;

            @media #{$mq8b} {
                height: auto;
                width: 80%;
                max-width: 500px;
                padding: 0;
            }
        }

        @media #{$mq8b} {
            justify-content: center;
            border: none;
        }
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        @media #{$mq8b} {
            justify-content: center;
            align-items: center;
        }

        .heading {
            font-weight: 600;
            font-size: 30px;
            padding-top: 0;
            margin: 0;
            line-height: 35px;
            padding-bottom: 10px;

            @media #{$mq7b} {
                font-size: 20px;
            }

        }

        .qrCode {
            width: 200px;
            height: auto;
        }

        .subHeading {
            margin: 0;
            font-weight: 500;
            font-size: 15px;
            color: #171A1FFF;
            text-align: left;
            font-family: $Epilogue;
            @media #{$mq7b} {
                font-size: 12px;
            }
        }

        .form {
            display: flex;
            gap: 10px;
            margin-top: 10px;

            input {
                width: 40px;
                height: 40px;
                text-align: center;
                font-size: 24px;
                border: 1px solid #ccc;
                border-radius: 4px;
            }
        }
    }
}