@import "../../assets/Fonts/Fonts.scss";

.switchComponentWrapper {
    label {
        display: flex;
        justify-content: center;
    }

    .MuiFormControlLabel-label {
        font-size: 14px;
        font-family: $Epilogue;
        margin-top: 2px;
        color: #000;
    }
}