@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/variable.scss";

.MuiInputLabel-root {
  color: #d32f2f;
}

$width: 365px;
$height: 36px;

.react-datepicker-wrapper {
  width: 100%;

  .react-datepicker__input-container {
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;

    input {
      width: 100%;
      border: 1px solid #9095a0;
      font-family: $Epilogue;
      border-radius: 10px;
    }
  }

  .react-datepicker__input-container input:focus-visible {
    border-color: #6f7787ff;
    outline: none;
  }

  .MuiSvgIcon-root {
    right: 0px;
  }

  .react-datepicker__view-calendar-icon input {
    padding: 9px 30px 9px 10px;
  }
}

.react-datepicker-popper {
  z-index: 100;
}

.InputLabelComponent {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: $Epilogue;
  color: #999;

  &.Mui-error {
    color: "red";
  }

  &.Mui-disabled {
    opacity: 1;
  }
}