@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/variable.scss";
@import "../../assets/responsive.scss";

.table_container {
  font-family: $Epilogue;
  padding-top: 15px;

  .MuiPaper-root {
    box-shadow: none;
    background-color: transparent;

    .MuiTableCell-head {
      background-color: #dee1e6ff;
      height: 55px;
      font-size: $subHeading;
      font-weight: 600;
      font-family: $Epilogue;
      line-height: 22px;
      color: var(--primary-color);

      .MuiButton-root {
        padding: 0;
        min-width: auto;
        margin-left: 0px;
        font-size: 14px;
        font-weight: 600;

        [class*="MUIDataTableHeadCell-sortAction"] {
          align-items: center;
        }

        [class*="MUIDataTableHeadCell-sortActive"] {
          color: var(--primary-color);
        }

        svg {
          color: var(--primary-color);
        }
      }
    }

    .MuiTableBody-root {
      background-color: #ffffff;
      color: #333;
    }

    .MuiTableCell-root {
      padding-top: 0;
      padding-bottom: 0;
      font-family: $Epilogue;
    }

    .MuiTableCell-body {
      height: 75px;
      padding: 0 16px;
      color: var(--primary-color);
      font-size: 12px;
    }

    .MuiTableFooter-root {
      .MuiTablePagination-toolbar {
        @media #{$mq7b} {
          padding-left: 0;
        }
      }

      .MuiTableCell-footer {
        border-bottom: none;
        font-family: $Epilogue !important;
        color: var(--primary-color);

        @media #{$mq7b} {
          font-size: 10px;
        }
      }

      .MuiTablePagination-selectLabel {
        font-family: $Epilogue;
        color: var(--primary-color);

        @media #{$mq7b} {
          font-size: 10px;
        }
      }

      .MuiTablePagination-select {
        font-family: $Epilogue;
        color: var(--primary-color);

        @media #{$mq7b} {
          font-size: 10px;
          padding-right: 20px;
          padding-left: 6px;
        }
      }

      .MuiTablePagination-displayedRows {
        font-family: $Epilogue;
        color: var(--primary-color);

        @media #{$mq7b} {
          font-size: 10px;
        }
      }

      .MuiTablePagination-actions {
        color: var(--primary-color);

        @media #{$mq7b} {
          font-size: 10px;
        }
      }

      .MuiSelect-icon {
        color: var(--primary-color);
        top: calc(50% - 14px);
      }
    }
  }

  .tableFooter {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
