@import "../../assets/Fonts/Fonts.scss";
@import "../../assets//variable.scss";

$width: 100%;
$height: 40px;
$padding-left: 12px;
$padding-right: 12px;

$font-size: 14px;
$font-weight: 400;
$line-height: 22px;
$background: #ffffffff;
$border-width: 1px;
$border-color: #999;
$outline: none;
$border-radius: 10px;

.FormControlComponent {
  width: 100%;

  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  border-width: $border-width;
  border-color: $border-color;
  outline: $outline;

  .InputLabelComponent {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: $Epilogue;
    color: #999999;
    padding-bottom: 5px;

    &.Mui-error {
      color: "red";
    }

    &.Mui-disabled {
      opacity: 1;
    }
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }

  .TextFieldComponent {
    margin: 0px;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    font-family: $Epilogue;
    // background: $background;
    border-width: $border-width;
    border-color: $border-color;
    outline: $outline;

    .MuiFilledInput-root {
      background-color: white;
      border-bottom: "10px";
      border-radius: 10px !important;
      min-height: $height !important;
      border: 1px solid grey;
      font-weight: 400;
      font-size: 14px;
      padding-left: 8px;
      -webkit-text-fill-color: #171a1fff;

      .MuiInputAdornment-root {
        margin-right: 0;
      }

      .MuiInputBase-input {
        padding: 7px 8px;
        font-family: $Epilogue;

        &::placeholder {
          font-family: $Epilogue;
          font-size: 12px;
        }


        &:disabled {
          border: none !important;
          -webkit-text-fill-color: #171a1fff;
        }
      }

      &:hover {
        cursor: not-allowed !important;
      }

      &.Mui-focused {
        background-color: transparent !important;
        cursor: not-allowed !important;
      }

      &.Mui-disabled {
        background-color: #f8f9fa;
        border: none;
      }
    }
  }
}

.HelperText {
  height: 0px;
  font-weight: 400;
  font-size: 0.75rem;
  font-family: $Epilogue !important;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px !important;
  margin-bottom: 0;
}

.Danger {
  color: #d32f2f;
}