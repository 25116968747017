@import "../../../assets/Fonts/Fonts.scss";
@import "../../../assets/variable.scss";
@import "../../../assets//responsive.scss";

.modalContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: #f3f4f6;
    padding: 28px 50px;

    .headingWrapper {
        display: flex;

        img {
            width: 40px;
            padding-left: 10px;
        }
    }

    .modalHeader {
        font-size: 36px;
        font-weight: 600;
        line-height: 48px;
        text-align: left;
        font-family: $Epilogue;
        margin: 0;
    }

    .formContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        gap: 30px;

        .requestDetails {
            display: flex;
            flex-direction: column;
            gap: 20px;
            background-color: #ffffff;
            padding: 20px;
        }

        .formHeader {
            font-size: 24px;
            font-weight: 600;
            line-height: 38px;
            font-family: $Epilogue;
            margin: 0;
        }

        .formFieldContainer {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .alignElement {
                padding-left: 20px;
                height: 96px;
                display: flex;
                align-items: center;

                button {
                    margin-top: 30px;
                }
            }
        }
    }

    .documentRequestedContainer {
        background: #ffffff;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .bulkUploadContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap: 20px;
        }
    }

}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.documentTypeModal {
    display: flex;
    flex-direction: column;
    padding: 12px 24px 20px;
    width: 590px;
    gap: 36px;

    .documentTypeModalHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: $subHeading;
            font-weight: 700;
            margin: 0;
            font-family: $Epilogue;
            color: var(--primary-color);
        }

    }

    .questionsContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .individualQuestion {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }
    }

    .documentTypeSelect {
        padding-bottom: 130px;
    }
}

.actionContainer {
    display: flex;
    align-items: center;
    gap: 10px;

}

.documentUpload {
    padding: 18px 0px;

    .textArea {
        width: 220px;
    }
}

.documentType {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    .documentTypeIcon {
        width: 20px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin: 0;
    }
}

.deleteModalContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px;
    font-size: $subHeading;
    font-family: $Epilogue;
    font-weight: 700;

    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
    }
}

.modalContent {
    width: 1000px;
    padding: 40px;
    overflow-x: hidden;
    position: relative;

    .maskBackground {
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: #d1d1d1;
        top: 52px;
        right: 52px;
        z-index: 20;
    }

    svg {
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 30px;
        cursor: pointer;
    }

    iframe {
        height: 100%;
        width: 100% !important;
    }
}