@import "../../../assets/Fonts/Fonts.scss";
@import "../../../assets/variable.scss";
@import "../../../assets/responsive.scss";

.header {
  display: flex;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  font-family: $Epilogue;
  // background-color: #f8f9fa;
  justify-content: space-between;
  overflow: hidden;

  .formHeader {
    padding-top: 15px;
    padding-left: 15px;
    font-size: 20px;
    font-weight: 500;
    font-family: $Epilogue;
    color: var(--primary-color);
  }

  .UserCard {
    display: flex;
    flex-direction: column;
    gap: 20px;

    grid-template-columns: repeat(3, minmax(100px, 1fr));
    margin: 0px 24px 20px;
    background: #ffffff !important;
    border-radius: 0px;
    border-width: 1px;
    border-color: #f3f4f6ff;
    border-style: solid;
    color: var(--primary-color);
    padding: 20px;

    .Form {
      display: flex;
      flex-direction: column;
      gap: 25px;
      flex-grow: 1;
      padding-left: 15px;
    }

    .BottomButtons {
      padding-right: 10px;
      padding-bottom: 10px;

      .BottomButton {
        margin-right: 10px;
      }

      .cancel {
        color: grey;
        border-width: 1px;
        border-color: grey;
      }

      .save {
        background-color: grey;
        color: white;
        border-width: 1px;
        border-color: rgb(110, 110, 110);
      }
    }
  }
}

.flex {
  display: flex;
  padding: 28px 24px;

  .headerText {
    font-size: 35px;
    font-weight: 600;
    font-family: $Epilogue;
    color: var(--primary-color);
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}

.viewUserContainer {
  min-width: 1000px;

  @media #{$mq12b} {
    min-width: 900px;
  }

  @media #{$mq9b} {
    min-width: calc(100vw - 100px);
  }
}