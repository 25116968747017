@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/variable.scss";
@import "../../assets/responsive.scss";

.archiveDashboardWrapper {
    padding-top: 2rem;
}

.modalContent {
    width: 400px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;

    p {
        font-weight: 500;
        font-family: $Epilogue;
        text-align: center;
    }
}