@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/variable.scss";
@import "../../assets/responsive.scss";

.gridWrapper {
    // background-color: #F8F9FAFF;
    padding: 20px 0;
    margin: 0 !important;
    height: 96%;
    min-height: calc(80vh - 64px);
    align-items: center;

    @media #{$mq12b} {
        height: 96%;
        min-height: auto;
    }


    .gridContentWrapper {
        background-color: #fff;
        padding: 22px;
        border-radius: 10px;
        cursor: pointer;



        .moduleIconWrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
            color: var(--primary-color);

            h1 {
                font-family: $Epilogue;
                font-size: 25px;
                margin: 0;
                font-weight: 500;
            }

            img {
                width: 45px;
            }
        }




        .fontSizeLarge {
            font-size: 30px;
        }

        .fontSizeSmall {
            font-size: 27px;
        }

        .gridheading {
            font-family: $Epilogue;
            font-size: 28px;
            color: var(--primary-color);
            font-weight: 600;

        }

        .gridButtonWrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            button {
                font-family: $Epilogue;
                border-radius: 10px;
                width: 160px;
                display: flex;
                justify-content: flex-start;
            }
        }
    }
}