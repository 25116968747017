@import "../../assets/Fonts/Fonts.scss";
@import "../../assets//variable.scss";

.AvatarLabel {
  display: flex;
  align-items: center;

  .email {
    font-size: 2px;
  }
}

.InputLabelComponent {
  position: unset;
  transform: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  font-family: $Epilogue;
  color: #999
}

.SearchableDropDown {
  width: 100%;
}

.HelperText {
  height: 0px;
  font-family: $Epilogue !important;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px !important;
  margin-bottom: 0;
}

.Danger {
  color: #d32f2f;
}