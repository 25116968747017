@import "../../assets/Fonts/Fonts.scss";
@import "../../assets/responsive.scss";

.section_header_container {
  display: flex;
  align-items: center;

  @media #{$mq8b} {
    justify-content: center;
  }

  .header_dropdown_container {
    display: flex;
    gap: 10px;

    input {
      width: 285px;
      height: 32px;
      padding: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      background: #ffffffff;
      border-width: 1px;
      border-color: #9095a0ff;
      outline: none;
      transition: color 0.3s, background 0.3s, border-color 0.3s;

      &::placeholder {
        font-size: 14px;
      }

      &:hover {
        color: #171a1fff;
        background: #ffffffff;
        border-color: #6f7787ff;
      }

      &:focus {
        color: #171a1fff;
        background: #ffffffff;
        border-color: #9095a0ff;
      }

      &:disabled {
        color: #171a1fff;
        background: #ffffffff;
        border-color: #9095a0ff;
      }
    }
  }

  .search_input_container {
    .MuiTextField-root {
      width: 100%;
    }

    .MuiOutlinedInput-root {
      width: 100%;
      background: #ffffffff;
      border-radius: 10px;
      height: 40px;
      gap: 5px;
      padding: 0px 8px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #9095a0ff;
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #9095a0ff;
    }

    input {
      width: 100%;
      height: 32px;
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      font-family: $Epilogue;

      &:hover {
        color: #171a1fff;
        background: #ffffffff;
        border-color: #6f7787ff;
      }

      &:active {
        color: #171a1fff;
        background: #ffffffff;
        border-color: #6f7787ff;
      }

      &:focus {
        color: #171a1fff;
        background: #ffffffff;
        border-color: #9095a0ff;
      }

      &:disabled {
        color: #171a1fff;
        background: #ffffffff;
        border-color: #9095a0ff;
      }
    }
  }

  .section_header_right {
    display: flex;
    // align-items: center;
    gap: 15px;
    justify-content: flex-end;

    @media #{$mq8b} {
      flex-direction: column;
    }

    .section_header_filters {
      display: flex;
      justify-content: flex-end;
      width: -webkit-fill-available;
      width: -moz-available;
    }
  }
}