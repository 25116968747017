@import "../../../assets/Fonts/Fonts.scss";
@import "../../../assets/variable.scss";
@import "../../../assets/responsive.scss";

.dashboardContainers {
  background: white;
  border-radius: 16px;
  padding: 24px;
  height: 100%;
  box-sizing: border-box;

  .header {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #1c1c1c;
  }

  th {
    background-color: rgba(var(--secondary-color-rgb), 0.1) !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    color: #000000 !important;
    height: 50px !important;

    button {
      font-size: 12px !important;
      font-weight: 500 !important;
      line-height: 16px !important;
      color: #000000 !important;
    }
  }

  td {
    min-height: 40px !important;
    height: auto !important;
    padding: 8px 16px !important;
  }
}

.notificationContainer {
  background: white;
  border-radius: 10px;
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(var(--secondary-color-rgb), 0.1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 8px;

    p {
      margin: 0;
      font-size: 18px;
      color: #1c1c1c;
      font-weight: 600;
    }

    .searchInputContainer {
      input {
        width: 100%;
        height: 28px;
        padding: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        font-family: $Epilogue;
      }
    }
  }

  .body {
    box-sizing: border-box;
    max-height: 450px;
    height: calc(100% - 44px);
    overflow-y: auto;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

    .noData {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 24px;
    }

    .activityContainer {
      border-bottom: 1px solid #1c1c1c0d;

      .activityHoverContainer {
        display: flex;
        flex-direction: column;
        padding: 8px;
        cursor: pointer;
        border-radius: 8px;

        .activityContent {
          display: flex;
          justify-content: space-between;
        }

        &:hover {
          background-color: rgba(var(--secondary-color-rgb), 0.1);
          border: 1px solid var(--secondary-color);
        }
      }

      p {
        margin: 0;
      }

      .clientContactName {
        font-size: 14px;
        color: #1c1c1c;
        line-height: 20px;
      }
      .message {
        font-size: 12px;
        color: #1c1c1c66;
        line-height: 16px;
      }
      .time {
        font-size: 12px;
        color: #1c1c1c66;
        line-height: 16px;
      }
    }

    .pagination {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 10px auto;
    }
  }
}
